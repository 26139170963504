@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: 'Averta';
    src: url('/fonts/avertastd-regular-webfont.woff2') format('woff2'),
      url('/fonts/avertastd-regular-webfont.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: auto;
  }

  @font-face {
    font-family: 'Averta';
    src: url('/fonts/avertastd-bold-webfont.woff2') format('woff2'),
      url('/fonts/avertastd-bold-webfont.woff') format('woff');
    font-weight: 700;
    font-style: bold;
    font-display: auto;
  }

  @font-face {
    font-family: 'Averta';
    src: url('/fonts/avertastd-semibold-webfont.woff2') format('woff2'),
      url('/fonts/avertastd-semibold-webfont.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: auto;
  }

  @font-face {
    font-family: 'Averta';
    src: url('/fonts/avertastd-extrabold-webfont.woff2') format('woff2'),
      url('/fonts/avertastd-extrabold-webfont.woff') format('woff');
    font-weight: 800;
    font-style: normal;
    font-display: auto;
  }

  html,
  body {
    @apply h-full font-averta font-normal antialiased bg-gray-50 w-full;
  }
}
